footer.FooterNavigation {
  background: #fff;
  padding: 21px 30px;
  border: 1px solid #375fb7;
}

.footeNavigateCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footeNavigateCont i {
  font-size: 30px;
  color: #872365;
}
