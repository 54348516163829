@import "webfonts/Lato/latofonts.css";
@import "webfonts/Lato/latostyle.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #dce2f5;
  font-family: "LatoWeb", sans-serif;
}
a {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.2;
}

.box-shadow {
  box-shadow: 0 0 5px #68686883;
}

.fz16 {
  font-size: 16px;
}

.fz18 {
  font-size: 18px;
}
.fz20 {
  font-size: 20px;
}

.fz22 {
  font-size: 22px;
}

.cd {
  color: #020000;
}

p {
  margin: 0 0 0 0;
  font-size: 16px;
  line-height: 1.5;
}

img {
  max-width: 100%;
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

.ml-15 {
  margin-left: -15px;
}

.mr-15 {
  margin-right: -15px;
}

.mb15 {
  margin-bottom: 15px;
}

.p15 {
  padding: 15px 0 !important;
}

.text-center {
  text-align: center;
}

.boRa {
  border-radius: 15px;
}

.fwn {
  font-weight: normal !important;
}

button {
  cursor: pointer;
}

.todo {
  color: red;
  font-weight: bold;
}

.miniMenu {
  padding: 10px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px #00000020;
  text-align: center;
}

.btnMiniDark {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  transition: 0.3s ease all;
  background: #494951;
  padding: 5px 20px;
  border-radius: 10px;
  box-shadow: 0 0px 2px 0px #00060029;
  display: inline-block;
  border: 1px solid #494951;
  margin: auto;
  width: 100%;
}

.radio {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
}

.radio--input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.radio--radio {
  background: #dce2f5;
  border-radius: 30px;
  box-shadow: 0 7px 9px 0 #0006;
  color: #494951;
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  padding: 10px 45px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
}

.radio--field {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  flex-wrap: wrap;
}
.radio--field p {
  width: 100%;
  flex-shrink: 0;
  margin: 20px 0 10px;
}

.radio--field textarea {
  width: 100%;
  flex-shrink: 0;
  margin: 10px 0;
}

.radio--field .radio {
  margin-right: 20px;
}

.radio--input:checked ~ .radio--radio {
  color: #fff;
  background: #494951;
}

.fileupload {
  position: relative;
  display: inline-block;
}

.fileupload input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  width: calc(100% - 74px);
}

.fileupload--display {
  background: #dce2f5;
  border-radius: 30px;
  box-shadow: 0 7px 9px 0 #0006;
  color: #494951;
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  /* height: 60px; */
  padding: 10px 45px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  max-width: 250px;
  text-align: justify;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fileupload--display.active {
  color: #fff;
  background: #494951;
}
input[type="text"],
input[type="number"],
textarea {
  /* background: #dce2f5; */
  border: 1px solid #000;
  /* outline: none; */
  padding: 10px;
  /* box-sizing: border-box;
  border-radius: 5px; */
}

.questionBox input[type="text"],
.questionBox input[type="number"],
.questionBox textarea {
  width: 75%;
}
