.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-Header {
  flex-grow: 0;
}

.app-body {
  flex-grow: 1;
}

.app-Footer {
  flex-grow: 0;
}

.app-error {
  color: #db007e;
  text-align: center;
}

.form_error {
  color: #db007e;
}

.error-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.error-overlay .whiteBox {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  min-width: 250px;
  max-width: 90%;
  max-height: 90%;
  padding: 30px 50px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
}
