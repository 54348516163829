.screen {
  width: 100%;
  min-height: 100%;
  max-width: 768px;
  margin: auto;
  display: flex;
  padding: 0 25px;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
}

.screen-header {
  flex-grow: 0;
}

.screen-body {
  flex-grow: 1;
  width: 100%;
  margin-bottom: 15px;
}

.screen-footer {
  flex-grow: 0;
  text-align: center;
}
