@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: translate(-7%, -17%) rotate(0deg);
    -o-transform: translate(-7%, -17%) rotate(0deg);
    transform: translate(-7%, -17%) rotate(0deg);
  }
  to {
    -webkit-transform: translate(-7%, -17%) rotate(360deg);
    -o-transform: translate(-7%, -17%) rotate(360deg);
    transform: translate(-7%, -17%) rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: translate(-7%, -17%) rotate(0deg);
    -moz-transform: translate(-7%, -17%) rotate(0deg);
    -webkit-transform: translate(-7%, -17%) rotate(0deg);
    -o-transform: translate(-7%, -17%) rotate(0deg);
    transform: translate(-7%, -17%) rotate(0deg);
  }
  to {
    -ms-transform: translate(-7%, -17%) rotate(360deg);
    -moz-transform: translate(-7%, -17%) rotate(360deg);
    -webkit-transform: translate(-7%, -17%) rotate(360deg);
    -o-transform: translate(-7%, -17%) rotate(360deg);
    transform: translate(-7%, -17%) rotate(360deg);
  }
}
.rotating {
}

section.loadScreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background-color: #000;
  background-image: linear-gradient(to right, #020202, #323537);
  width: 100%;
  height: 100%;
}

.loadScreenContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 66px);
  width: 50%;
  max-width: 150px;
  margin: auto;
  position: relative;
}

.loadScreenContent .loadingScreenHead {
  width: 100%;
  position: absolute;
}
.loadScreenContent .loadingScreenSpinner {
  width: 49%;
  position: absolute;
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
}
